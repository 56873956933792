// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-groups-tsx": () => import("./../../../src/pages/groups.tsx" /* webpackChunkName: "component---src-pages-groups-tsx" */),
  "component---src-pages-howto-tsx": () => import("./../../../src/pages/howto.tsx" /* webpackChunkName: "component---src-pages-howto-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-rules-tsx": () => import("./../../../src/pages/rules.tsx" /* webpackChunkName: "component---src-pages-rules-tsx" */),
  "component---src-pages-study-tsx": () => import("./../../../src/pages/study.tsx" /* webpackChunkName: "component---src-pages-study-tsx" */)
}

