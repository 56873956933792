module.exports = [{
      plugin: require('../node_modules/@snek-at/jaen/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@snek-at/jaen-pages/gatsby-browser.js'),
      options: {"plugins":[],"templates":{"HousePage":"/home/runner/work/tuwien-club-front/tuwien-club-front/src/templates/HousePage/index.tsx","ApartmentPage":"/home/runner/work/tuwien-club-front/tuwien-club-front/src/templates/ApartmentPage/index.tsx","BlogPage":"/home/runner/work/tuwien-club-front/tuwien-club-front/src/templates/BlogPage/index.tsx"}},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true,"isUsingColorMode":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"snek site","short_name":"snek site","start_url":"/","background_color":"#6b37bf","theme_color":"#6b37bf","display":"standalone","icon":"src/common/logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"80f5cf5f99650631aca8c95f9cc14d1f"},
    }]
